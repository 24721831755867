import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Drawer as MUIDrawer,
  Grid,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { CURRENT_PATH } from 'utils/constants';

import { AccountingType } from '../../../v2-types/order';
import Balance from './components/balance';
import Drawer from './components/drawer';
import TableEndorsements from './components/endorsements-list';
import Header from './components/header';
import Lenders from './components/lenders';
import Navigation from './components/navigation';
import PolicyTypeForm from './components/policy-type-form';
import FormPremiums from './components/premiums-form';
import { SkeletonPolicy } from './components/skeleton-policy';
import TablePolicyTaxesAndFees from './components/taxes-and-fees';
import AutoSave from './services/autosave';
import PolicyContext, { updateType } from './services/context';
import { FormPolicy, ListingsType } from './services/types';

const useStyles = makeStyles((theme: any) => ({
  rootContainer: { padding: theme.spacing(3) },
  mainContainer: { padding: theme.spacing(3) },
  alertContainer: { marginBottom: theme.spacing(4) },
  alertLink: {
    fontWeight: 'bold',
    textDecoration: 'none',
    borderBottom: '1px dotted',
    color: theme.palette.secondary.main,
  },
  alertLinkIcon: { marginLeft: theme.spacing(0.7) },
  alertItalic: { fontStyle: 'italic' },
  lendersContainer: { marginTop: theme.spacing(2) },
  formPolicyTypeContainer: { marginTop: theme.spacing(2) },
  tableEndorsementsContainer: { marginTop: theme.spacing(4) },
  tableOwnersPolicyTaxesAndFeesContainer: { marginTop: theme.spacing(2) },
  formPremiumsContainer: { marginTop: theme.spacing(5) },
  calculatorContainer: { paddingLeft: theme.spacing(2) },
  drawer: {
    width: 400,
    flexShrink: 0,
  },
  drawerPaper: { width: 400 },
}));

type PolicyRefactorSceneProps = {
  methods: UseFormReturn<FormPolicy>;
  accounting: AccountingType[];
  options: ListingsType;
  selectedPolicy?: 'owners_policy' | 'lenders_policy';
  handleSelectPolicy: (param: 'owners_policy' | 'lenders_policy') => void;
  handleSetAccounting: (accountingData: AccountingType[]) => void;
  handleSave: (formValues: FormPolicy) => void;
  selectedEndorsement: AccountingType | null;
  handleSelectEndorsement: (accountindInfo: AccountingType | null) => void;
  info: updateType;
  handleUpdateInfo: (infovalues: updateType) => void;
  typeTransaction: string;
  loadingData: boolean;
  deletedEndorsements: AccountingType[],
  setDeletedEndorsements: React.Dispatch<React.SetStateAction<AccountingType[]>>,
  temporalCreatedEndorsements: AccountingType[],
  setTemporalCreatedEndorsements: React.Dispatch<React.SetStateAction<AccountingType[]>>
};

const PolicyRefactorScene: FC<PolicyRefactorSceneProps> = ({
  loadingData,
  accounting,
  methods,
  options,
  selectedPolicy,
  handleSelectPolicy,
  handleSetAccounting,
  handleSave,
  selectedEndorsement,
  handleSelectEndorsement,
  info,
  handleUpdateInfo,
  typeTransaction,
  deletedEndorsements,
  setDeletedEndorsements,
  temporalCreatedEndorsements,
  setTemporalCreatedEndorsements
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const closeDrawer = () => {
    handleSelectEndorsement(null);
  };

  return (
    <PolicyContext.Provider
      value={{
        accounting,
        setAccounting: handleSetAccounting,
        options,
        loading: false,
        latestUpdate: info,
        setLatestUpdate: handleUpdateInfo,
        deletedEndorsements,
        setDeletedEndorsements,
        temporalCreatedEndorsements,
        setTemporalCreatedEndorsements
      }}
    >
      <Grid container direction="row" className={classes.rootContainer}>
        {loadingData || selectedPolicy === undefined ? (
          <SkeletonPolicy />
        ) : (
          <FormProvider {...methods}>
            <Grid item xs={8}>
              <Paper>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Header />
                  </Grid>

                  <Grid item xs={12} className={classes.mainContainer}>
                    <Grid container direction="column">
                      <Grid item xs={12} className={classes.alertContainer}>
                        <Alert severity="info">
                          <Typography>
                            {`${t('policyInfoAndRates:alert-p-1')} `}

                            <Link
                              to={`/orders/details/${id}/properties/`}
                              className={classes.alertLink}
                              onClick={() => {
                                localStorage.setItem(
                                  CURRENT_PATH,
                                  JSON.stringify({ view: 'properties' })
                                );
                              }}
                            >
                              {t('orders:properties')}
                              <FontAwesomeIcon
                                icon="link"
                                className={classes.alertLinkIcon}
                              />
                            </Link>
                          </Typography>
                        </Alert>
                      </Grid>
                      <Grid item xs={12}>
                        <Navigation
                          selectedPolicy={selectedPolicy}
                          handleSelectPolicy={handleSelectPolicy}
                          typeTransaction={typeTransaction}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {selectedPolicy === 'lenders_policy' && (
                          <Lenders isUserAdmin />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.formPolicyTypeContainer}
                      >
                        <PolicyTypeForm selectedPolicy={selectedPolicy} />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.tableEndorsementsContainer}
                      >
                        <TableEndorsements
                          selectedPolicy={selectedPolicy}
                          handleSelectEndorsement={handleSelectEndorsement}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.formPremiumsContainer}
                      >
                        <TablePolicyTaxesAndFees
                          selectedPolicy={selectedPolicy}
                          handleSelectEndorsement={handleSelectEndorsement}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.formPremiumsContainer}
                      >
                        <FormPremiums selectedPolicy={selectedPolicy} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <AutoSave handleSave={handleSave} />
            <Grid item xs={4} className={classes.calculatorContainer}>
              <Paper>
                <Balance typeTransaction={typeTransaction} />
              </Paper>
            </Grid>
          </FormProvider>
        )}

        <MUIDrawer
          open={selectedEndorsement !== null}
          anchor="right"
          variant="persistent"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
        >
          <Toolbar />
          {selectedEndorsement && (
            <Drawer
              accountingInfo={selectedEndorsement}
              handleClose={closeDrawer}
            />
          )}
        </MUIDrawer>
      </Grid>
    </PolicyContext.Provider>
  );
};

export default PolicyRefactorScene;
