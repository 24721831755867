import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import NumberField from 'components/number-field';
import TextField from 'components/text-field';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  underline: {
    fontSize: theme.spacing(2),
    color: theme.palette.primary.light,
    fontWeight: 500,
    width: '100%',
    paddingLeft: theme.spacing(1),
    '&&&:before': { borderBottom: 'none' },
    '&&:after': { borderBottom: 'none' },
    '&:hover': { background: theme.palette.input.border },
  },
  helperText: {
    position: 'relative',
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  }
}));

const InputCell = ({
  value,
  onChangeInput,
  placeholder,
  helperText,
  disabled,
  onClickEdit,
  prefix,
  thousandSeparator
}: any) => {
  const classes = useStyles();
  return (
    <div>
      <NumberField
        naked
        placeholder={placeholder}
        value={value}
        onChange={onChangeInput}
        disabled={disabled}
        prefix={prefix}
        thousandSeparator={thousandSeparator}
        InputProps={{
          endAdornment: onClickEdit && (
            <IconButton
              style={{ padding: 0 }}
              onClick={onClickEdit}
            >
              <FontAwesomeIcon icon="edit" size="xs" />
            </IconButton>
          ),
        }}
      />

      {helperText && <Typography color="secondary" className={classes.helperText}>{helperText}</Typography>}
    </div>
  );
};

export default InputCell;
