import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { formatMoney } from 'utils/helpers';
import { AccountingInvolvedParty } from 'v2-types/order';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    gap: 15,
    marginBottom: 20,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    width: '100%',
  },
  containerInfo: {
    display: 'flex',
    gap: 10,
    justifyContent: 'space-between',
  },
  infoName: {
    alignItems: 'center',
    display: 'flex',
    gap: 3,
  },
  text: {
    textTransform: 'capitalize',
    color: theme.palette.primary.darker,
    width: 'max-content',
    fontSize: 14,
  },
  amount: {
    textTransform: 'capitalize',
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 'bolder',
  },
  kind: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
    fontSize: 14,
    width: 'max-content',
  },
  amountInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  btn: {
    textTransform: 'capitalize',
    color: theme.palette.error.main,
  },
}));

type Props = {
  parties: AccountingInvolvedParty[];
  disbursmentId: string;
  amountEntry: number;
  handleDeleteAssociation: (accountingId: string, partyId: string, kind: string) => void;
  handleConfirmDelete: (
    openConfirm: boolean,
    callback: (() => void) | null,
    title: string,
    confirmationMessage: string
  ) => void;
  t: TFunction;
};

const DisbursmentsPartiesCell: FC<Props> = ({
  parties,
  amountEntry,
  handleDeleteAssociation,
  disbursmentId,
  t,
  handleConfirmDelete,
}) => {
  const classes = useStyles();

  return (
    <>
      {parties.map((item) => {
        const orderKinds = item.order_kinds.sort((a, b) => a.localeCompare(b));
        const isAmountsDifferents = amountEntry !== item.amount;
        return (
          <Box className={classes.container} key={item._id}>
            <Box className={classes.mainContainer}>
              <Box className={classes.containerInfo}>
                <Box className={classes.infoName}>
                  <Typography className={classes.text}>{item.name}</Typography>
                  <Typography className={classes.kind}>{`(${item.kind})`}</Typography>
                </Box>
                <Box className={classes.amountInfo}>
                  {isAmountsDifferents && (
                    <Typography className={classes.amount}>{formatMoney(item.amount)}</Typography>
                  )}
                </Box>
              </Box>
              <Typography className={classes.text}>{orderKinds.join(', ')}</Typography>
            </Box>
            <Button
              className={classes.btn}
              onClick={() =>
                handleConfirmDelete(
                  true,
                  () => handleDeleteAssociation(disbursmentId, item._id, item.kind),
                  t('disbursments:titleparty'),
                  t('disbursments:confirmationMessageParty')
                )}
            >
              {t('disbursments:delete')}
            </Button>
          </Box>
        );
      })}
    </>
  );
};

export default DisbursmentsPartiesCell;
