import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ContainedButton } from 'components/ContainedButton';
import NumberField from 'components/number-field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { DisabledFields } from 'types/disabledFields';
import { listingItemtype, PartyAccounting } from 'types/ledger';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import SearchContact from '../../views/orders/components/simple-search-contact';
import ListAccounted from './components/list-accounted';
import { AddAndUpdatePartyLedgerForm } from './types';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(3) },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  icon: {
    fontSize: '16px',
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  itempaddinType: {
    '& > * ': { marginTop: theme.spacing(2) },
    padding: theme.spacing(3),
    margin: 0,
  },
  itempaddinfoother: { '& > * ': { marginLeft: theme.spacing(2) } },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

type AddAndUpdatePartyLedgerSceneProps = {
  onClose: () => void;
  methods: UseFormReturn<AddAndUpdatePartyLedgerForm, object>;
  t: TFunction;
  handleSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  handleSelectParty: (user: PartyAccounting | null) => void;
  allowedAssociations: listingItemtype[];
  currentPartySelected: PartyAccounting | null;
  errors: string[];
  loadingAddAssociation: boolean;
  loadingEditAssociation: boolean;
  entryLedger: AccountingType;
  partyEntryPrevInfo?: AccountingInvolvedParty;
  orderParties: PartyAccounting[];
  filterParties?: string;
  hideFields?: DisabledFields<AddAndUpdatePartyLedgerForm>;
};

const calculate = (outputtype: 'amount' | 'percentaje', input: number, total: number) => {
  if (outputtype === 'percentaje' && total) {
    return (input * 100) / total;
  }
  if (outputtype === 'amount' && total) {
    return (input * total) / 100;
  }
  return 0;
};

export const AddAndUpdatePartyLedgerScene = ({
  onClose,
  methods,
  entryLedger,
  allowedAssociations,
  handleSubmit,
  handleSelectParty,
  currentPartySelected,
  errors,
  partyEntryPrevInfo,
  t,
  orderParties,
  loadingAddAssociation,
  loadingEditAssociation,
  filterParties,
  hideFields,
}: AddAndUpdatePartyLedgerSceneProps) => {
  const classes = useStyles();
  const { control, setValue, getValues } = methods;
  const prevEditedAmount =
    entryLedger.amount_override && entryLedger.amount_override > 0
      ? entryLedger.amount_override
      : partyEntryPrevInfo?.amount!;
  const ledgerOriginalAmount =
    entryLedger.amount_override && entryLedger.amount_override > 0
      ? entryLedger.amount_override
      : entryLedger.amount;
  const payments = partyEntryPrevInfo
    ? orderParties.find((party) => party._id === partyEntryPrevInfo?._id)?.accounts
    : currentPartySelected?.accounts;
  const totalAmount = partyEntryPrevInfo ? prevEditedAmount : ledgerOriginalAmount;

  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ padding: 0 }}>
          <Grid container className={classes.itempaddinType} direction="column">
            <Typography variant="h6" style={{ color: 'black' }}>
              {`${
                partyEntryPrevInfo
                  ? `${t('accounting:edit-association-for')} ${entryLedger.description}`
                  : `${t('accounting:add-new-party-for')} ${entryLedger.description || ''}`
              } `}
            </Typography>
            {!!errors.length && (
              <Grid item xs={12}>
                <Alert severity="error" classes={{ icon: classes.errorIconContainer }}>
                  {errors.map((error) => (
                    <Typography variant="subtitle1">{error}</Typography>
                  ))}
                </Alert>
              </Grid>
            )}
            {partyEntryPrevInfo ? (
              <TextField
                value={partyEntryPrevInfo?.name || ''}
                disabled
                label={t('parties:contact')}
              />
            ) : (
              <SearchContact
                getValue={(user) => {
                  handleSelectParty(user);
                  if (user === null) {
                    setValue('payment_id', null);
                  }
                  if (user) {
                    setValue('party_id', user._id);
                  }
                }}
                parties={orderParties}
                filterParties={filterParties}
              />
            )}
            {!hideFields?.kind && (
              <Controller
                name="kind"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SelectField
                    {...field}
                    value={allowedAssociations.length > 0 ? field.value : ''}
                    inputRef={ref}
                    data={allowedAssociations}
                    dataKey="code"
                    dataValue="code"
                    dataText="description"
                    label={t('users:kind')}
                    disabled={!!partyEntryPrevInfo}
                    handleChange={field.onChange}
                  />
                )}
              />
            )}
            {!hideFields?.full_amount && (
              <NumberField
                decimalScale={9}
                label={t('accounting:full-amount')}
                value={totalAmount || 0}
                disabled
              />
            )}
            <Grid container direction="row" spacing={3}>
              {!hideFields?.amount && (
                <Grid item xs={6}>
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <NumberField
                        customRef={ref}
                        decimalScale={9}
                        label={t('lender:amount')}
                        value={field.value}
                        isAllowed={({ floatValue = 0 }) => floatValue <= totalAmount}
                        handleBlur={field.onBlur}
                        onChange={(e) => {
                          field.onChange(e);
                          const calculateNumber = calculate(
                            'percentaje',
                            parseFloat(e.target.value),
                            totalAmount || 0
                          );
                          const before_closing_percent = getValues('before_closing_percent');
                          const at_Closing_percent = getValues('at_closing_percent');
                          if (before_closing_percent > 0 || at_Closing_percent > 0) {
                            const percentbeforeclosing = calculate(
                              'percentaje',
                              before_closing_percent,
                              parseFloat(e.target.value)
                            );
                            const percentAfterclosing = 100 - percentbeforeclosing;
                            const amountBeforeClosing = calculate(
                              'amount',
                              percentbeforeclosing,
                              parseFloat(e.target.value)
                            );
                            const amountAfterClosing =
                              parseFloat(e.target.value) - amountBeforeClosing;
                            setValue('before_closing_percent', percentbeforeclosing);
                            setValue('before_closing_amount', amountBeforeClosing);
                            setValue('at_closing_percent', percentAfterclosing);
                            setValue('at_closing_amount', amountAfterClosing);
                          }
                          setValue('percent', calculateNumber || 0);
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {!hideFields?.percent && (
                <Grid item xs={6}>
                  <Controller
                    name="percent"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <NumberField
                        {...field}
                        customRef={ref}
                        decimalScale={9}
                        label={t('accounting:percent')}
                        suffix="%"
                        isAllowed={({ floatValue = 0 }) => floatValue <= 100}
                        value={field.value}
                        handleBlur={field.onBlur}
                        onChange={(e) => {
                          field.onChange(e);
                          const calculateNumber = calculate(
                            'amount',
                            parseFloat(e.target.value),
                            totalAmount || 0
                          );
                          const before_closing_percent = getValues('before_closing_percent');
                          const at_Closing_percent = getValues('at_closing_percent');
                          if (before_closing_percent > 0 || at_Closing_percent > 0) {
                            const percentbeforeclosing = calculate(
                              'percentaje',
                              before_closing_percent,
                              calculateNumber
                            );
                            const percentAfterclosing = 100 - percentbeforeclosing;
                            const amountBeforeClosing = calculate(
                              'amount',
                              percentbeforeclosing,
                              calculateNumber
                            );
                            const amountAfterClosing = calculateNumber - amountBeforeClosing;
                            setValue('before_closing_percent', percentbeforeclosing);
                            setValue('before_closing_amount', amountBeforeClosing);
                            setValue('at_closing_percent', percentAfterclosing);
                            setValue('at_closing_amount', amountAfterClosing);
                          }
                          setValue('amount', calculateNumber || 0);
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container direction="row" spacing={3}>
              {!hideFields?.before_closing_amount && (
                <Grid item xs={6}>
                  <Controller
                    name="before_closing_amount"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <NumberField
                        {...field}
                        customRef={ref}
                        decimalScale={9}
                        value={field.value}
                        handleBlur={field.onBlur}
                        disabled
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        label={t('accounting:before-closing-amount')}
                      />
                    )}
                  />
                </Grid>
              )}
              {!hideFields?.before_closing_percent && (
                <Grid item xs={6}>
                  <Controller
                    name="before_closing_percent"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <NumberField
                        {...field}
                        customRef={ref}
                        decimalScale={9}
                        isAllowed={({ floatValue = 0 }) => floatValue <= 100}
                        value={field.value}
                        suffix="%"
                        handleBlur={field.onBlur}
                        disabled
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        label={t('accounting:before-closing-percent')}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" spacing={3}>
              {!hideFields?.at_closing_amount && (
                <Grid item xs={6}>
                  <Controller
                    name="at_closing_amount"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <NumberField
                        {...field}
                        customRef={ref}
                        decimalScale={9}
                        value={field.value}
                        handleBlur={field.onBlur}
                        isAllowed={({ floatValue = 0 }) => floatValue <= getValues('amount')}
                        label={t('accounting:at-closing-amount')}
                        onChange={(e) => {
                          field.onChange(e);
                          const amountSelected = getValues('amount');
                          const calculateNumber = calculate(
                            'percentaje',
                            parseFloat(e.target.value),
                            amountSelected
                          );
                          setValue('at_closing_percent', calculateNumber || 0);
                          setValue('before_closing_percent', 100 - calculateNumber);
                          setValue(
                            'before_closing_amount',
                            calculate('amount', 100 - calculateNumber, amountSelected)
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {!hideFields?.at_closing_percent && (
                <Grid item xs={6}>
                  <Controller
                    name="at_closing_percent"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <NumberField
                        {...field}
                        customRef={ref}
                        decimalScale={9}
                        value={field.value}
                        handleBlur={field.onBlur}
                        isAllowed={({ floatValue = 0 }) => floatValue <= 100}
                        suffix="%"
                        onChange={(e) => {
                          field.onChange(e);
                          const amountSelected = getValues('amount');
                          const calculateNumber = calculate(
                            'amount',
                            parseFloat(e.target.value),
                            amountSelected
                          );
                          setValue('at_closing_amount', calculateNumber || 0);
                          setValue('before_closing_percent', 100 - parseFloat(e.target.value));
                          setValue(
                            'before_closing_amount',
                            calculate('amount', 100 - parseFloat(e.target.value), amountSelected)
                          );
                        }}
                        label={t('accounting:at-closing-percent')}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            {partyEntryPrevInfo?.accounted_by && partyEntryPrevInfo?.accounted_by?.length > 0 && (
              <ListAccounted
                list={partyEntryPrevInfo.accounted_by}
                total={partyEntryPrevInfo?.accounted_amount || 0}
              />
            )}
            {payments?.length ? (
              <Controller
                name="payment_id"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SelectField
                    {...field}
                    value={field.value || ''}
                    inputRef={ref}
                    data={payments || []}
                    dataKey="_id"
                    dataValue="_id"
                    dataText="reference"
                    label={t('accounting:payment-id')}
                    handleChange={field.onChange}
                  />
                )}
              />
            ) : (
              <Typography>{t('accounting:cant-get-info')}</Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" disableElevation onClick={onClose}>
            <Typography variant="body2" className={classes.text}>
              {t('common:close')}
            </Typography>
          </Button>

          <ContainedButton
            text={`${
              partyEntryPrevInfo
                ? t('accounting:edit-association')
                : t('accounting:add-association')
            } `}
            icon="hdd"
            isLoading={loadingAddAssociation || loadingEditAssociation}
            type="submit"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
