import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { formatMoney } from 'utils/helpers';
import { AccountedBy } from 'v2-types/order';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    gap: '15px',
    marginBottom: '20px',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '100%',
  },
  containerInfo: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
  },
  infoName: {
    alignItems: 'center',
    display: 'flex',
    gap: '3px',
  },
  text: {
    textTransform: 'capitalize',
    color: theme.palette.primary.darker,
    fontSize: 14,
    width: 'max-content',
  },
  kind: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
    fontSize: 14,
    width: 'max-content',
  },
  amount: {
    textTransform: 'capitalize',
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 'bolder',
    width: 'max-content',
  },
  amountInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  diffContainer: {
    display: 'flex',
    gap: 10,
  },
  btn: {
    textTransform: 'capitalize',
    color: theme.palette.error.main,
  },
}));

type Props = {
  accounted: AccountedBy[] | undefined;
  handledeleteLink: (
    paymentRecordId: string,
    associationKind: string,
    associationId: string,
    associationLedgerRow: string
  ) => void;
  disbursmentId: string;
  t: TFunction;
  handleConfirmDelete: (
    openConfirm: boolean,
    callback: (() => void) | null,
    title: string,
    confirmationMessage: string
  ) => void;
};

const DisbursmentsAccountedCell: FC<Props> = ({
  accounted,
  handledeleteLink,
  disbursmentId,
  t,
  handleConfirmDelete,
}) => {
  const classes = useStyles();

  return (
    <>
      {accounted?.map((item) => {
        const { amount, record } = item;
        const { amount: amountRecord, letter, number, _id } = record;
        const {
          name = '',
          order_kinds = [],
          kind = '',
          _id: partyId = '',
        } = item.record?.involved_parties?.[0] || {};
        const orderKinds = order_kinds.sort((a, b) => a.localeCompare(b));

        const diffAmounts = amountRecord - amount;
        return (
          <Box className={classes.container} key={_id}>
            <Box className={classes.mainContainer}>
              <Box className={classes.containerInfo}>
                <Box className={classes.infoName}>
                  <Typography className={classes.text}>{item.record.description}</Typography>
                  {letter && number && (
                    <Typography className={classes.amount}>{`(${letter}-${number})`}</Typography>
                  )}
                  <Typography className={classes.text}>{name}</Typography>
                  <Typography className={classes.kind}>{`(${kind})`}</Typography>
                </Box>
                <Box className={classes.amountInfo}>
                  <Typography className={classes.amount}>{formatMoney(amount)}</Typography>
                </Box>
              </Box>
              <Box className={classes.footerContainer}>
                <Typography className={classes.text}>{orderKinds.join(', ')}</Typography>
                {diffAmounts !== 0 && (
                  <Box className={classes.diffContainer}>
                    <Typography className={classes.kind}>{t('disbursments:rest')}</Typography>
                    <Typography className={classes.amount}>{formatMoney(diffAmounts)}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Button
              className={classes.btn}
              onClick={() =>
                handleConfirmDelete(
                  true,
                  () => handledeleteLink(disbursmentId, kind, partyId, _id),
                  t('disbursments:titleAccounted'),
                  t('disbursments:confirmationMessageAccounted')
                )}
            >
              {t('disbursments:delete')}
            </Button>
          </Box>
        );
      })}
    </>
  );
};

export default DisbursmentsAccountedCell;
