import { Grid, makeStyles } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import { SearchPartyInput } from 'components/search-party-input';
import SelectField from 'components/select-field';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import graphql from 'services/querys';
import useAlert from 'utils/alert';
import { NewOrderPartyType } from 'types/order';
import { useParams } from 'react-router-dom';

const allowedAssociationArray = [
  { text: 'Spouse' },
  { text: 'Attorney' },
  { text: 'Agent' },
  { text: 'Power of Attorney' },
  { text: 'Exchange 1031' },
  { text: 'Real Estate Agent' },
  { text: 'Transaction Coordinator' }
];

type FormAddPArtyType = {
  role: string,
  party: {
    _id: string,
    name: string
  }
}

type AddPartyAssociatedProps = {
  filterParties: ((parties: NewOrderPartyType[]) => void) | undefined,
  party_id: string,
  kind: string
}

const AddPartyAssociatiatedForm: FC<AddPartyAssociatedProps> = ({ filterParties, party_id, kind }) => {
  const { t } = useTranslation();
  const showAlert = useAlert();
  const { id: orderId } = useParams<{ id: string }>();
  const { control, setValue, handleSubmit, reset, formState } = useForm<FormAddPArtyType>({
    defaultValues: {
      role: '',
      party: {
        _id: '',
        name: ''
      }
    }
  });

  const [addPartyRelated, addPartyRelatedResponse] = useMutation(
    graphql.createAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          showAlert('Party Associated', 'success');
          if (data?.createOrderAssociation.parties && filterParties) {
            filterParties(data?.createOrderAssociation?.parties!);
          }
          reset({ party: { _id: '', name: '' }, role: '' });
          addPartyRelatedResponse.reset();
        }, 1500);
      },
      onError: () => {
        addPartyRelatedResponse.reset();
        showAlert(t('parties:mutation-error'), 'error');
      },
    });
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="party._id"
          render={() => (
            <SearchPartyInput
              onSelectParty={(contact) => setValue('party', { _id: contact?._id!, name: contact?.name! })}
              disabled={addPartyRelatedResponse.isLoading}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          name="role"
          rules={{ required: true }}
          render={({ field }) => (
            <SelectField
              {...field}
              value={allowedAssociationArray.length > 0 ? field.value : ''}
              inputRef={field.ref}
              data={allowedAssociationArray}
              dataKey="text"
              dataValue="text"
              dataText="text"
              label={t('users:kind')}
              handleChange={field.onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <BaseButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleSubmit((data) =>
              addPartyRelated({
                id: orderId,
                partyId: data.party._id,
                linkedToId: party_id,
                kind: data.role,
                linkedToKind: kind,
              }))(e);
          }}
          text="Add Party"
          isLoading={addPartyRelatedResponse.isLoading}
          disabled={addPartyRelatedResponse.isLoading || !formState.isValid}
        />
      </Grid>
    </Grid>
  );
};

export default AddPartyAssociatiatedForm;