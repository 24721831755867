import React, { FC } from 'react';
import { Control, Controller, UseFormReturn } from 'react-hook-form';
import { formType } from '../services';
import { Box, IconButton, InputAdornment, makeStyles, Popover, Typography } from '@material-ui/core';
import TextField from 'components/text-field';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberField from 'components/number-field';
import { BaseButton } from 'components/BaseButton';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.error.main,
    },
  },
  removePadding: {
    '& .MuiFilledInput-adornedStart': {
      marginRight: 4,
      padding: 0
    },
    '& .MuiInputAdornment-root': {
      marginRight: 4,
      padding: 0
    }
  },
  description: { fontWeight: 500, color: 'black', marginBottom: theme.spacing(1) },
  textFound: { fontWeight: 500, color: '#607D8B', marginBottom: 12 },
}));

type cellEditableProps = {
  methods: UseFormReturn<formType, any, undefined>
  name: any,
  label?: string,
  rows?: number,
  required?: boolean,
  showDelete?: boolean,
  onDelete?: () => void,
  text?: boolean,
  localValue?: number
}

const CellEditable: FC<cellEditableProps> = ({
  methods,
  name,
  label,
  rows,
  required,
  showDelete,
  onDelete,
  text,
  localValue
}) => {
  const classes = useStyles();
  const { control, getValues, setValue } = methods;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Box
      style={{ width: '100%', }}
      display="flex"
      flexDirection="row"
    >
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { ref, ...field } }) => (
          text ?
            <TextField
              {...field}
              fullWidth
              label={label}
              variant="standard"
              value={getValues(name)}
              InputProps={{
                disableUnderline: true,
                size: 'small',
                margin: 'dense',
                endAdornment: showDelete && (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onDelete) {
                          onDelete();
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" style={{ fontSize: '14px' }} />
                    </IconButton>
                  </InputAdornment>),
                style: {
                  fontSize: '14px',
                  color: 'black',
                  padding: 0
                }
              }}
              multiline
              customClassName={clsx({ [classes.errorInput]: field.value === '' && required })}
              rows={rows || 0}
            /> :
            <NumberField
              {...field}
              value={getValues(name)}
              naked
              className={classes.removePadding}
              InputProps={{
                disableUnderline: true,
                size: 'small',
                margin: 'dense',
                startAdornment: showDelete && (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onDelete) {
                          onDelete();
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="trash-alt" style={{ fontSize: '14px' }} />
                    </IconButton>
                  </InputAdornment>),
                style: {
                  fontSize: '14px',
                  color: 'black',
                }
              }}
            />
        )}
      />
      {(localValue !== undefined && localValue !== 0) && (
        <IconButton
          style={{ padding: 0 }}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon="info-circle" style={{ fontSize: '14px', color: 'red' }} />
        </IconButton>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        keepMounted={false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >

        <Box style={{ padding: 12, width: 500 }}>
          <Typography variant="body2" className={classes.description}>We found a charge with the same letter and number with this value:</Typography>
          <Typography variant="body2" className={classes.textFound}>{`${localValue || ''}`}</Typography>
          <BaseButton
            text="Use Value"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
              setValue(name, localValue);
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default CellEditable;
