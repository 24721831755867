import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import graphql from 'services/querys';
import { NewOrderPartyType } from 'types/order';
import useAlert from 'utils/alert';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import { partyWithAssociationType } from 'views/orders/new-parties/services';

const useStyles = makeStyles((theme: any) => ({
  itemParty: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1)
  }
}));

type itemPartyRelatedProps = {
  filterParties: ((parties: NewOrderPartyType[]) => void) | undefined,
  party: partyWithAssociationType
}

const ItemPartyRelated: FC<itemPartyRelatedProps> = ({ filterParties, party, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const [deleteagent, deleteagentResponse] = useMutation(
    graphql.deleteAssociation,
    {
      onSuccess: (data) => {
        showAlert('Party Deleted', 'success');
        if (data?.deleteOrderAssociation.parties && filterParties) {
          filterParties(data?.deleteOrderAssociation?.parties!);
        }
        deleteagentResponse.reset();
      },
      onError: () => {
        deleteagentResponse.reset();
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );

  return (
    <Grid item xs={12}>
      <Grid container direction="column" className={classes.itemParty}>
        <Grid item style={{ padding: 0 }}>
          <Grid container direction="row">

            <Grid item xs={6}>
              <Typography variant="body2" style={{ fontWeight: 500, color: 'black' }}>{party.partyInfo.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2" style={{ fontWeight: 500, color: '#607D8B' }}>{party.associationInfo.linked_as}</Typography>
                </Grid>
                <Grid item>
                  <BaseButton
                    text="Remove"
                    textColor="white"
                    size="small"
                    disableElevation
                    customStyle={{
                      backgroundColor: 'red'
                    }}
                    disabled={deleteagentResponse.isLoading}
                    isLoading={deleteagentResponse.isLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteagent({
                        id: orderId,
                        partyId: party.partyInfo._id,
                        kind: party?.associationInfo.linked_as,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ padding: 0 }}>
          {children}
        </Grid>

      </Grid>
    </Grid>
  );
};

export default ItemPartyRelated;