import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import InputCell from './input-cell';
import { Controller, UseFormReturn } from 'react-hook-form';
import { projectedPayments } from '../services';
import { formatCurrencyValueToNumber } from 'utils/helpers';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    height: 'auto',
    '&>*': {
      marginRight: theme.spacing(4),
      height: 'auto !important',
      flex: 1,
    },
  },
  textField: {
    maxWidth: '50%',
    height: theme.spacing(4),
    '&>.MuiInputBase-root': {
      height: theme.spacing(4),
      '&>.MuiSelect-root': {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
  },
  rangeAmounts: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  }
}));

type PrincipalInterestAmountFieldsProps = {
  methods: UseFormReturn<projectedPayments, any, undefined>
};

const PrincipalInterestAmountFields = ({
  methods,
}: PrincipalInterestAmountFieldsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { watch, control } = methods;
  const principalKind = watch('principal_kind');
  const data = [{
    name: 'amount',
    text: t('projectedPayments:amount'),
  }, {
    name: 'range',
    text: t('projectedPayments:range'),
  }];

  return (
    <span className={classes.root}>
      <Controller
        control={control}
        name="principal_kind"
        render={({ field }) => (
          <TextField
            select
            fullWidth
            variant="filled"
            value={field.value}
            className={classes.textField}
            InputProps={{ disableUnderline: true }}
            onChange={(e) => field.onChange(e)}
          >
            {data.map((element: any) => (
              <MenuItem
                key={element.name}
                value={element.name}
              >
                {element.text}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      {
        principalKind === 'amount'
          ? (
            <Controller
              control={control}
              name="principal_amount"
              render={({ field }) => (
                <InputCell
                  prefix="$"
                  thousandSeparator
                  placeholder={t('projectedPayments:amount')}
                  value={field.value}
                  onChangeInput={(e) => { field.onChange(formatCurrencyValueToNumber(e.target.value)); }}
                />
              )}
            />
          )
          : principalKind === 'range' && (
            <span className={classes.rangeAmounts}>
              <Controller
                control={control}
                name="principal_min"
                render={({ field }) => (
                  <InputCell
                    placeholder={t('projectedPayments:min')}
                    value={field.value}
                    onChangeInput={(e) => field.onChange(e)}
                  />
                )}
              />

              <Controller
                control={control}
                name="principal_max"
                render={({ field }) => (
                  <InputCell
                    placeholder={t('projectedPayments:max')}
                    value={field.value}
                    onChangeInput={(e) => field.onChange(e)}
                  />
                )}
              />
            </span>
          )
      }

    </span>
  );
};

export default PrincipalInterestAmountFields;
