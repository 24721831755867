import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import clsx from 'clsx';
import { AccountingType } from 'v2-types/order';
import { TFunction } from 'i18next';

const useStyles = makeStyles((theme: any) => ({
  container: { display: 'flex', gap: '4px' },
  btn: {
    fontSize: 16,
    fontWeight: 600,
  },
  add: {
    color: theme.palette.secondary.main,
  },
  link: {
    color: theme.palette.info.main,
  },
  edit: {
    color: theme.palette.success.main,
  },
  trash: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  disbursmentItem: AccountingType;
  handleDeleteEntry: (accountingId: string) => void;
  handleModals: (modal: string | null, entryInfo: AccountingType | null) => void;
  handleConfirmDelete: (
    openConfirm: boolean,
    callback: (() => void) | null,
    title: string,
    confirmationMessage: string
  ) => void;
  t: TFunction;
};

export const DisbursmentsBtns: FC<Props> = ({
  handleDeleteEntry,
  handleModals,
  disbursmentItem,
  handleConfirmDelete,
  t,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <IconButton
        className={clsx({
          [classes.btn]: true,
          [classes.add]: true,
        })}
        onClick={() => handleModals('create', disbursmentItem)}
      >
        <FontAwesomeIcon icon="user" />
      </IconButton>
      <IconButton
        className={clsx({
          [classes.btn]: true,
          [classes.link]: true,
        })}
        onClick={() => handleModals('link', disbursmentItem)}
      >
        <FontAwesomeIcon icon="link" />
      </IconButton>
      <IconButton
        className={clsx({
          [classes.btn]: true,
          [classes.edit]: true,
        })}
        onClick={() => handleModals('edit', disbursmentItem)}
      >
        <FontAwesomeIcon icon="edit" />
      </IconButton>
      <IconButton
        className={clsx({
          [classes.btn]: true,
          [classes.trash]: true,
        })}
        onClick={() =>
          handleConfirmDelete(
            true,
            () => handleDeleteEntry(disbursmentItem._id),
            t('disbursments:titleDisbursement'),
            t('disbursments:confirmationMessageDisbursement')
          )}
      >
        <FontAwesomeIcon icon="trash" />
      </IconButton>
    </Box>
  );
};

export default DisbursmentsBtns;
