import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import TextField from 'components/text-field';
import { format } from 'date-fns';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { AccountingType } from 'v2-types/order';

import { createEndorsement } from '../graphql/mutations';
import usePolicy from '../services/use-policy-context';
import useSearchEndorsement from '../services/useSearchEndorsement';

const useStyles = makeStyles((theme: any) => ({

  addButton: {
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  addButtonIcon: { marginRight: theme.spacing(1) },
  cardContainer: {
    height: 250,
    overflow: 'auto',
  },
  cardItemContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  cardItemSelected: { backgroundColor: theme.palette.background.default },
  cardText: { color: theme.palette.primary.darker },
  cardButtonContainer: {
    padding: 0,
    borderTopWidth: 1,
    borderTopColor: theme.palette.input.border,
    borderTopStyle: 'solid',
  },
  cardButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardButtonText: { color: theme.palette.tab.offselected },
  icon: { marginLeft: theme.spacing(1) },
}));

type SearchEndorsementProps = {
  selectedPolicy: 'owners_policy' | 'lenders_policy'
};

const EndorsementSearch: FC<SearchEndorsementProps> = ({ selectedPolicy }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const context = usePolicy();
  const {
    accounting,
    setAccounting,
    options,
    setLatestUpdate,
    setTemporalCreatedEndorsements,
    temporalCreatedEndorsements = []
  } = context || {};
  const { endorsements: endorsementsOptions } = options || {};
  const { endorsements, handleOnChange, search } = useSearchEndorsement(endorsementsOptions || []);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openAddButton = Boolean(anchorEl);
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();

  const [creatEndorsementMutation, creatEndorsementMutationResponse] = useMutation(createEndorsement);

  const handleClickAddButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAddButton = () => {
    setAnchorEl(null);
  };

  const handleAddendorsement = useCallback(({ description, code }: { description: string, code: string }) => {
    const kind = selectedPolicy === 'owners_policy' ? 'owners' : 'lenders';
    const temporalCode = `${kind}_${code}`;
    if (temporalCreatedEndorsements.some(endorsement => endorsement.code === temporalCode)) return;

    const newEndorsement: AccountingType = {
      _id: "",
      code: temporalCode,
      kind: "premium",
      description,
      amount: 0,
      amount_calculated: 0,
      per_month: 0,
      amount_override: 0,
      entry_date: "",
      involved_parties: [],
      letter: "",
      number: 0,
      months: 0,
    };

    if (setTemporalCreatedEndorsements) {
      setTemporalCreatedEndorsements(prevEndorsements => {
        if (prevEndorsements.some(endorsement => endorsement.code === temporalCode)) return prevEndorsements;
        return [
          ...prevEndorsements,
          newEndorsement
        ];
      });
    }

    creatEndorsementMutation({
      id: orderId,
      kind,
      code
    }, {
      onSuccess: () => {
        creatEndorsementMutationResponse.reset();
        if (setLatestUpdate) {
          showAlert(t('policyInfoAndRates:endorsementAdded'), 'success');
          const now = format(new Date(), 'hh:mm');
          setLatestUpdate({ time: now, type: 'success', message: `updated at  ${now}` });
        }
      },
      onError: (error) => {
        if (setTemporalCreatedEndorsements) {
          if (error instanceof Error && error.message.includes("Existing endorsement")) {
            showAlert(t("policyInfoAndRates:endorsementExisting"), "error");
          }
          setTemporalCreatedEndorsements(prevEndorsements => prevEndorsements.filter(entry => entry.code !== temporalCode));
        }
      }
    });
  }, [creatEndorsementMutation, orderId, selectedPolicy, setAccounting, accounting, setTemporalCreatedEndorsements]);

  return (
    <Grid item>
      <Button
        disableElevation
        variant="outlined"
        color="secondary"
        size="small"
        className={classes.addButton}
        onClick={handleClickAddButton}
      >
        <FontAwesomeIcon size="sm" icon="plus" className={classes.addButtonIcon} />
        {t('policyInfoAndRates:add-endorsement')}
      </Button>

      <Popover
        open={openAddButton}
        anchorEl={anchorEl}
        onClose={handleCloseAddButton}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardContent style={{ paddingBottom: 0 }}>
            <TextField
              variant="standard"
              name="search-endorsement"
              value={search}
              onChange={handleOnChange}
              InputProps={{
                endAdornment: (
                  <FontAwesomeIcon
                    icon="search"
                    size="sm"
                    className={classes.icon}
                  />
                ),
              }}
            />
          </CardContent>

          <CardContent className={clsx({ [classes.cardContainer]: endorsements.length > 3 })}>
            {endorsements.length === 0 && (
              <Typography className={classes.cardText}>
                {t('policyInfoAndRates:no-endorsement')}
              </Typography>
            )}

            {endorsements.map((endorsement) => (
              <CardActionArea
                key={endorsement.code}
                id={`policy_info_and_rates.${selectedPolicy}.endorsements`}
                className={classes.cardItemContainer}
                onClick={() => handleAddendorsement(endorsement)}
              >
                <Typography className={classes.cardText}>
                  {`${endorsement.description}`}
                </Typography>
              </CardActionArea>
            ))}
          </CardContent>
        </Card>
      </Popover>
    </Grid>
  );
};

export default EndorsementSearch;
