import { AccountingType } from 'v2-types/order';
import * as yup from 'yup';

export const validationSchemaAdd = yup.object({
  code: yup.string().required('Required'),
  description: yup.string().required('Required'),
  entryDate: yup.string().required('Required'),
  amount: yup
    .number()
    .typeError('Amount must be a number')
    .positive('Amount must be greater than 0')
    .required('Required'),
});

export const validationSchemaEdit = yup.object({
  code: yup.string().required('Required'),
  description: yup.string().required('Required'),
  entryDate: yup.string().required('Required'),
  amount_override: yup
    .number()
    .typeError('Amount must be a number')
    .positive('Amount must be greater than 0')
    .required('Required'),
});

export const getHideFields = {
  edit: {
    amount: true,
    months_advanced: true,
    annuality: true,
    number: true,
    letter: true,
    months: true,
    kind: true,
  },
  add: {
    amount_override: true,
    months_advanced: true,
    annuality: true,
    number: true,
    letter: true,
    months: true,
    kind: true,
  },
  other: {},
};

export const filterEntriesToDisbursment = (accounting: AccountingType[]) => {
  const filteredDisbursments = accounting
    .filter(
      (entry) =>
        entry.code.toLowerCase() === 'cash' ||
        entry.code.toLowerCase() === 'wire' ||
        entry.code.toLowerCase() === 'check'
    )
    .sort((a, b) => a.description.localeCompare(b.description));

  const accountingFiltered = accounting
    .map((entry) => ({
      ...entry,
      description:
        entry.letter && entry.number
          ? `${entry.description} (${entry.letter}-${entry.number})`
          : entry.description,
      involved_parties: entry.involved_parties.filter((party) => party.amount > 0),
    }))
    .filter((entry) => entry.involved_parties.length > 0)
    .sort((a, b) => a.description.localeCompare(b.description));
  return {
    filteredDisbursments,
    accountingFiltered,
  };
};
