/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { Grid, Typography } from '@material-ui/core';
import React, { FC, useContext, useMemo } from 'react';
import PartiesContext from 'views/orders/new-parties/services/context';
import AddPartyAssociatiatedForm from './components/add-party-form';
import { partyWithAssociationType } from 'views/orders/new-parties/services';
import ItemPartyRelated from './components/item-related-party';
import { useFormContext } from 'react-hook-form';
import { UpdateOrderSpecific } from 'types/order';
import Poaform from './components/poa-form';
import ExchangeForm from './components/exchange-form';

type relatedPartiesProps = {
  party_id: string,
  kind: string,
}

const RelatedParties: FC<relatedPartiesProps> = ({ party_id, kind }) => {
  const context = useContext(PartiesContext);
  const { unfilteredParties, filterParties } = context || {};
  const methods = useFormContext<UpdateOrderSpecific>();
  const memoizedParties = useMemo(() => {
    return unfilteredParties?.reduce(
      (prev, curr): partyWithAssociationType[] => {
        const mapped = curr?.associations?.map(f => ({
          partyInfo: curr,
          associationInfo: f
        }));
        return [...prev, ...mapped || []];
      }, [] as any as partyWithAssociationType[]).filter(p => p.associationInfo.party_id === party_id && p.associationInfo.party_kind === kind);
  }, [party_id, unfilteredParties]);

  return (
    <Grid container direction="column" spacing={3} style={{ padding: 24, minHeight: '100vh' }}>
      <Grid item xs={12}>
        <Typography variant="body1" style={{ fontWeight: 500, color: 'black', marginBottom: 16 }}>
          Add Party
        </Typography>
        <AddPartyAssociatiatedForm
          filterParties={filterParties}
          party_id={party_id}
          kind={kind}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" style={{ fontWeight: 500, color: 'black', marginBottom: 16 }}>
          Related Parties
        </Typography>
        <Grid container direction="column" spacing={2}>
          {memoizedParties?.map(party => (
            <ItemPartyRelated
              filterParties={filterParties}
              key={`${party.associationInfo.linked_as}-${party.partyInfo._id}`}
              party={party}
            >
              {party.associationInfo.linked_as === 'Power of Attorney'
                && (
                  <Poaform
                    methods={methods}
                    poaID={party.partyInfo._id}
                    filterParties={filterParties}
                  />
                )}
              {party.associationInfo.linked_as === 'Exchange 1031'
                &&
                (
                  <ExchangeForm
                    methods={methods}
                    exchangeId={party.partyInfo._id}
                    filterParties={filterParties}
                  />
                )}
            </ItemPartyRelated>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatedParties;