import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Tabs,
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import { CustomTab } from 'components/common/custom-tab';
import SaveButton from 'components/common/save-button';
import React, { FC } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { NewUserType } from 'types/new-user';
import { NewBuyerSellerType, UpdateOrderSpecific } from 'types/order';
import {
  BUYER,
  INFORMATION,
  NOTARY_BLOCKS,
  SIGNATURE_AND_VESTING,
} from 'utils/constants';

import InformationTab from '../components/information-tab';
import SkeletonParties from '../components/skeleton-loading';
import NotaryBlocks from '../components/tab-notary-block/notary-blocks';
import Tabsignature from '../components/tab-signature/tab-signature';
import TitleAction from '../components/title-action';
import RelatedParties from '../components/related-parties/related-parties';

type BuyerSceneType = {
  currentTab: string;
  handleTabChange: (event: any, index: string) => void;
  buyerInfo: NewUserType | undefined;
  t: TFunction;
  methods: UseFormReturn<UpdateOrderSpecific, any>;
  handleSaveInfo: (data_buyer: NewBuyerSellerType) => void;
  isLoading: boolean;
  isModal: boolean;
  loaderActive: boolean;
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>;
  roleParty: 'Buyer' | 'Seller'
};

const useStyles = makeStyles((theme: any) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
  },
  container: { padding: `${theme.spacing(6)}px ${theme.spacing(4)}px` },
  containerModal: { padding: `${theme.spacing(2)}px ${theme.spacing(2)}px` },
  containerCommon: {
    overflow: 'scroll',
    height: '68vh',
  },
  tabsContainer: {
    flex: 1,
    borderBottom: '1px solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.2)',
    height: theme.spacing(2),
  },
  tabContextContainer: { padding: 0 },
  title: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  containerTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const BuyerScene: FC<BuyerSceneType> = ({
  currentTab,
  isModal,
  handleTabChange,
  buyerInfo,
  t,
  methods,
  handleSaveInfo,
  isLoading,
  loaderActive,
  setLoaderActive,
  roleParty
}) => {
  const classes = useStyles();

  const onSubmit = (data) => {
    const values = roleParty === 'Buyer' ? data.data_buyer : data.data_seller;
    handleSaveInfo(values);
  };

  if (isLoading) {
    return <SkeletonParties />;
  }

  return (
    <Paper
      elevation={0}
      className={clsx({
        [classes.container]: isModal === false,
        [classes.containerModal]: isModal === true,
        [classes.containerCommon]: true,
      })}
    >
      {buyerInfo && (
        <FormProvider {...methods}>
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                className={classes.containerTitle}
              >
                <Grid item>
                  <TitleAction
                    partyId={buyerInfo._id}
                    type={roleParty}
                    name={buyerInfo?.name || ''}
                  />
                </Grid>
                <Grid item>
                  <SaveButton onClick={methods.handleSubmit(onSubmit)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TabContext value={currentTab}>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabsContainer}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <CustomTab
                    currentValue={currentTab}
                    value={INFORMATION}
                    text={t('common:information')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={NOTARY_BLOCKS}
                    text={t('orders:notary-blocks')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value={SIGNATURE_AND_VESTING}
                    text={t('orders:signature-vesting')}
                  />
                  <CustomTab
                    currentValue={currentTab}
                    value="RELATED_PARTIES"
                    text="Related Parties"
                  />
                </Tabs>
                <TabPanel
                  value={INFORMATION}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <InformationTab
                      party={buyerInfo}
                      t={t}
                      mainParty
                      methods={methods}
                      prefix={roleParty === 'Buyer' ? "data_buyer" : "data_seller"}
                    />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={SIGNATURE_AND_VESTING}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <Tabsignature roleParty={roleParty} />
                  </Box>
                </TabPanel>
                <TabPanel
                  value={NOTARY_BLOCKS}
                  className={classes.tabContextContainer}
                >
                  <Box>
                    <NotaryBlocks
                      party={buyerInfo}
                      roleParty={roleParty}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="RELATED_PARTIES" className={classes.tabContextContainer}>
                  <Box>
                    <RelatedParties party_id={buyerInfo._id} kind={roleParty} />
                  </Box>
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </FormProvider>
      )}
      <Backdrop className={classes.backdrop} open={loaderActive}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default BuyerScene;
