import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { v4 } from 'uuid';

import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2)}px`,
  },
  containerItems: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
}));

const SkeletonDisbursments = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <Skeleton variant="text" width={200} height={60} />
        <Skeleton variant="text" width={150} height={60} />
      </Box>
      <Box className={classes.containerItems}>
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton variant="text" width={300} height={40} />
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton variant="text" width={300} height={40} />
        <Skeleton variant="text" width={150} height={40} />
        <Skeleton variant="text" width={150} height={40} />
      </Box>
      {Array.from({ length: 9 }).map((_) => (
        <Box key={v4()} className={classes.containerItems}>
          <Skeleton variant="rect" width={150} height={40} />
          <Skeleton variant="rect" width={300} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
          <Skeleton variant="rect" width={300} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
          <Skeleton variant="rect" width={150} height={40} />
        </Box>
      ))}
    </Box>
  );
};

export default SkeletonDisbursments;
