import { TFunction } from 'i18next';
import { OrderListingType } from 'types/order';
import { IntlCurrencyFormat } from 'utils/helpers';

export type FiltersType = 'all' | 'active' | 'closed' | 'draft' | 'in_progress' | 'on_hold' | 'open';

export type CurrentData = {
  page: number;
  pages: number;
};

type Column = {
  id: string;
  label: string;
  render: (order: OrderListingType) => string;

};

export const columns = (t: TFunction, shouldRenderAgencyColumn: boolean): Column[] => {
  const columnList: Column[] = [{
    id: 'order_id',
    label: t('orders:order-id'),
    render: (order) => order.order_id,
  }];

  if (shouldRenderAgencyColumn) {
    columnList.push({
      id: 'agency',
      label: t('common:agency'),
      render: (order) => order.agency,
    });
  }

  return columnList.concat(
    [
      {
        id: 'address',
        label: t('orders:address'),
        render: (order) => order.address || t('common:pending'),
      },
      {
        id: 'seller',
        label: t('common:seller'),
        render: (order) => order.seller || t('common:pending'),
      },
      {
        id: 'buyer',
        label: t('common:buyer'),
        render: (order) => order.buyer || t('common:pending'),
      },
      {
        id: 'transaction_type',
        label: t('orders:transaction-type'),
        render: (order) => order.transaction_type || t('common:pending'),
      },
      {
        id: 'type',
        label: t('orders:type'),
        render: (order) => order.type || t('common:pending'),
      },
      {
        id: 'closing_date',
        label: t('orders:closing-date'),
        render: (order) => order.closing_date || t('common:pending'),
      },
      {
        id: 'purchase_price',
        label: t('orders:purchase-price'),
        render: (order) => (order.purchase_price ? IntlCurrencyFormat(order.purchase_price) : t('common:pending')),
      },
      {
        id: 'loan_amount',
        label: t('orders:load-amount'),
        render: (order) => (order.loan_amount ? IntlCurrencyFormat(order.loan_amount) : t('common:pending')),
      },
    ]
  );
};

export const filters = (t: TFunction): { id: string; value: FiltersType; label: string }[] => [
  {
    id: 'all',
    value: 'all',
    label: t('orders:all-orders'),
  },
  {
    id: 'open',
    value: 'open',
    label: t('orders:open'),
  },
  {
    id: "closed",
    value: "closed",
    label: t("orders:closed")
  }
];

export const reducerModals = (state, action) => {
  switch (action.type) {
    case 'new-order':
      return { mode: 'new-order', };
    case 'start-document-order':
      return {
        mode: 'start-document-order',
        data: action.data
      };
    default:
      return {
        mode: null,
        data: null
	  };
  }
};

export const intialReducerState = {
  mode: null,
  data: null
};

export default { };
