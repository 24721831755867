import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryCache } from "react-query";
import { NewOrderPartyType, NewPOAType, UpdateOrderSpecific } from "types/order";
import graphql from 'services/querys';
import { BaseButton } from "components/BaseButton";
import { useParams } from "react-router-dom";
import DatePicker from "components/date-picker/date-picker";
import useAlert from "utils/alert";

type Poaformtype = {
  methods: UseFormReturn<UpdateOrderSpecific, any, undefined>,
  poaID: string;
  filterParties: ((parties: NewOrderPartyType[]) => void) | undefined,
}

const Poaform: FC<Poaformtype> = ({ methods, poaID, filterParties }) => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const [updateOrderSpecifics, updateOrderSpecificsResponse] = useMutation(graphql.udpdateOrderSpecific, {
    onSuccess: (data) => {
      showAlert('Buyer Updated', 'success');
      if (data?.UpdateOrderSpecifics?.parties && filterParties) {
        filterParties(data?.UpdateOrderSpecifics.parties!);
      }
      updateOrderSpecificsResponse.reset();
    },
    onError: () => {
      updateOrderSpecificsResponse.reset();
      showAlert(t('parties:mutation-error'), 'error');
    },
  });

  const handleSaveOrderSpecifics = ({ dataPOA }: { dataPOA: NewPOAType }) => {
    if (orderId) {
      updateOrderSpecifics({
        id: orderId,
        partyId: poaID,
        dataPoa: dataPOA,
      });
    }
  };

  return (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item xs={9}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <Controller
              name="data_power_of_attorney.start_date"
              control={methods.control}
              render={({ field: { ref, value, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  value={value as string}
                  disableToolbar={false}
                  label={t('parties:poa_start')}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="data_power_of_attorney.end_date"
              control={methods.control}
              render={({ field: { ref, value, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  value={value as string}
                  disableToolbar={false}
                  label={t('parties:poa_end')}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <BaseButton
          text="Save Information"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const values = methods.getValues();
            handleSaveOrderSpecifics({ dataPOA: values.data_power_of_attorney! });
          }}
          isLoading={updateOrderSpecificsResponse.isLoading}
          disabled={updateOrderSpecificsResponse.isLoading}
        />
      </Grid>
    </Grid>

  );
};

export default Poaform;