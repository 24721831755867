import React, { FC, memo } from 'react';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';
import { chargesAIColumns, formType } from '../services';
import { useTranslation } from 'react-i18next';
import { Grid, useTheme } from '@material-ui/core';
import TableComponent from 'components/Table';
import { BaseButton } from 'components/BaseButton';

type FormExistingChargesProps = {
  methods: UseFormReturn<formType, any, undefined>
  letter: string
}

const FormExistingCharges: FC<FormExistingChargesProps> = ({ methods, letter }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control } = methods;
  const { fields, remove, append } = useFieldArray({ control, name: 'charges' });
  const formattedFields = fields.map((field, index) => ({ ...field, index, indexRow: index }));
  const columns = chargesAIColumns('charges', methods, t, theme, remove);
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        <TableComponent
          dataSet={formattedFields.filter(f => f.letter === letter)}
          columns={columns}
          disabledBorderLeft
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <BaseButton
          buttonVariant="outlined"
          text={`Add charge in ${letter}`}
          icon="plus"
          textColor="#FB8C00"
          iconColor="#FB8C00"
          onClick={(e) => {
            e.stopPropagation();
            append({
              letter,
              number: 0,
              description: 'Put the charge Description Here',
              buyer: {
                at_closing: 0,
                before_closing: 0
              },
              seller: {
                at_closing: 0,
                before_closing: 0
              },
              by_others: 0,
              payee: {
                id: '',
                name: ''
              },
              payeeText: ''
            });
          }}
        />
      </Grid>
    </Grid>

  );
};

export default memo(FormExistingCharges);