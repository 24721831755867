import React, { FC } from 'react';
import { NewBuyerSellerType } from 'types/order';
import { NewUserType } from 'v2-types/user';

import useBuyerHooks from './complex-party-hooks';
import BuyerScene from './complex-party-scene';

type BuyerProps = {
  party: NewUserType | undefined;
  isPartyLoading: boolean;
  isModal: boolean;
  roleParty: 'Buyer' | 'Seller'
};

export const Buyer: FC<BuyerProps> = ({ party, isPartyLoading, isModal, roleParty }) => {
  const {
    currentTab,
    setcurrentTab,
    t,
    methods,
    udpdateBuyerData,
    orderId,
    loaderActive,
    setLoaderActive,
  } = useBuyerHooks(party?._id!, roleParty);

  const handleTabChange = (event: any, index: string) => {
    setcurrentTab(index);
  };

  const handleSaveInfo = (data_buyer: NewBuyerSellerType) => {
    if (orderId && party?._id) {
      udpdateBuyerData({
        id: orderId,
        partyId: party?._id,
        ...(roleParty === 'Buyer' ? { dataBuyer: data_buyer } : { dataSeller: data_buyer })
      });
    }
  };

  return (
    <BuyerScene
      isModal={isModal}
      handleTabChange={handleTabChange}
      currentTab={currentTab}
      buyerInfo={party}
      t={t}
      methods={methods}
      handleSaveInfo={handleSaveInfo}
      isLoading={isPartyLoading}
      loaderActive={loaderActive}
      setLoaderActive={setLoaderActive}
      roleParty={roleParty}
    />
  );
};
