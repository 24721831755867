import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { createlinkForm } from '../types';
import SelectField from 'components/select-field';
import NumberField from 'components/number-field';

export const ModalLink = ({ handleClose, accounting, handleCreationLink, entry }) => {
  const methods = useForm<createlinkForm>();
  const { setValue, control, formState, handleSubmit } = methods;
  const selectedEntry = useWatch({
    control,
    name: 'targetEntryId',
  });

  const selectedAssociationId = useWatch({
    control,
    name: 'targetAssociationId',
  });

  const selectedEntryLedger = accounting.find((f) => f._id === selectedEntry);

  const selectedAssociation = selectedEntryLedger?.involved_parties?.find(
    (f) => f._id === selectedAssociationId
  );

  useEffect(() => {
    if (selectedAssociation) {
      const restAmount = entry.accounted_for.reduce((acc, num) => acc - num.amount, entry.amount);
      const sumAccountedAmount = selectedEntryLedger?.involved_parties?.reduce(
        (acc, num) => acc + num.accounted_amount,
        0
      );
      let amountRecomend = selectedAssociation.amount - sumAccountedAmount;
      amountRecomend = restAmount <= amountRecomend ? restAmount : amountRecomend;
      setValue('amount', amountRecomend);
    }
  }, [selectedAssociation]);

  const handleSubmitForm = handleSubmit((data) => {
    handleCreationLink(data);
  });

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>Create Link to Entry</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmitForm}>
          <Box marginBottom={2}>
            <Controller
              name="targetEntryId"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <SelectField
                  {...field}
                  inputRef={ref}
                  value={field.value || ''}
                  data={accounting}
                  dataKey="_id"
                  dataValue="_id"
                  dataText="description"
                  label="Select entry"
                  handleChange={(e) => {
                    field.onChange(e);
                    setValue('targetAssociationId', null);
                    setValue('targetAssociationKind', null);
                  }}
                />
              )}
            />
          </Box>
          <Box marginBottom={2}>
            <Controller
              name="targetAssociationId"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <SelectField
                  {...field}
                  value={field.value || ''}
                  inputRef={ref}
                  data={selectedEntryLedger?.involved_parties || []}
                  label="Select association"
                  dataKey="_id"
                  dataValue="_id"
                  dataText={['name', 'kind']}
                  handleChange={(e) => {
                    field.onChange(e);
                    const selectedParty = selectedEntryLedger?.involved_parties.find(
                      (g) => g._id === e.target.value
                    );
                    if (selectedParty) {
                      setValue('targetAssociationKind', selectedParty?.kind);
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box marginBottom={2}>
            <Controller
              name="amount"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <NumberField
                  {...field}
                  value={field.value || 0}
                  customRef={ref}
                  thousandSeparator=","
                  prefix="$"
                  label="Amount"
                  onChange={undefined}
                  onValueChange={(e) => {
                    setValue('amount', e.floatValue || 0);
                  }}
                  error={!!formState.errors.amount}
                />
              )}
            />
          </Box>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleClose()}
              disableElevation
            >
              <Typography variant="body2">close</Typography>
            </Button>
            <Button color="secondary" variant="contained" type="submit" disableElevation>
              <Typography variant="body2">Add</Typography>
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModalLink;
