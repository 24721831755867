import { Box, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import DisbursmentsPartiesCell from './disbursmentsPartiesCell';
import moment from 'moment';
import DisbursmentsAccountedCell from './disbursmentsAccounted';
import { formatMoney } from 'utils/helpers';
import DisbursmentsBtns from './disbursmentsBtns';
import { AccountingType } from 'v2-types/order';
import { TFunction } from 'i18next';

const useStyles = makeStyles((theme: any) => ({
  date: {
    color: theme.palette.primary.darker,
    fontSize: 14,
    width: 'max-content',
  },
  unAccountedContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'space-between',
  },
  text: {
    color: theme.palette.primary.darker,
    fontSize: 14,
    width: 'max-content',
    textTransform: 'capitalize',
  },
  amount: {
    textTransform: 'capitalize',
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 'bolder',
  },
}));

type DisbursmentRowProps = {
  disbursmentItem: AccountingType;
  handleDeleteEntry: (accountingId: string) => void;
  handleModals: (modal: string | null, entryInfo: AccountingType | null) => void;
  handleDeleteAssociation: (accountingId: string, partyId: string, kind: string) => void;
  handledeleteLink: (
    paymentRecordId: string,
    associationKind: string,
    associationId: string,
    associationLedgerRow: string
  ) => void;
  handleConfirmDelete: (openConfirm: boolean, callback: (() => void) | null, title: string, confirmationMessage: string) => void;
  t: TFunction;
};

const DisbursmentRow: FC<DisbursmentRowProps> = ({
  disbursmentItem,
  handleDeleteEntry,
  handleModals,
  handleDeleteAssociation,
  handledeleteLink,
  handleConfirmDelete,
  t,
}) => {
  const classes = useStyles();

  const { accounted_for, amount, code, description, entry_date, involved_parties } =
    disbursmentItem;

  const sumAmountAccounted = accounted_for?.reduce((acc, num) => acc + num.amount, 0) ?? 0;

  return (
    <TableRow>
      <TableCell align="center">
        <Typography className={classes.text}>{description}</Typography>
      </TableCell>
      <TableCell>
        <DisbursmentsPartiesCell
          amountEntry={amount}
          parties={involved_parties}
          handleDeleteAssociation={handleDeleteAssociation}
          disbursmentId={disbursmentItem._id}
          handleConfirmDelete={handleConfirmDelete}
          t={t}
        />
      </TableCell>
      <TableCell align="center">
        <Typography className={classes.text}>{formatMoney(amount)}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography className={classes.text}>{code}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography className={classes.date}>{moment(entry_date).format('MM-DD-YYYY')}</Typography>
      </TableCell>
      <TableCell>
        <DisbursmentsAccountedCell
          accounted={accounted_for}
          handledeleteLink={handledeleteLink}
          disbursmentId={disbursmentItem._id}
          handleConfirmDelete={handleConfirmDelete}
          t={t}
        />
      </TableCell>
      <TableCell align="center">
        {sumAmountAccounted !== amount && sumAmountAccounted > 0 && (
          <Box className={classes.unAccountedContainer}>
            <Typography className={classes.text}>{t('disbursments:unaccounted')}</Typography>
            <Typography className={classes.amount}>
              {formatMoney(amount - sumAmountAccounted)}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell align="center">
        <DisbursmentsBtns
          handleDeleteEntry={() => handleDeleteEntry(disbursmentItem._id)}
          handleModals={handleModals}
          disbursmentItem={disbursmentItem}
          handleConfirmDelete={handleConfirmDelete}
          t={t}
        />
      </TableCell>
    </TableRow>
  );
};

export default DisbursmentRow;
