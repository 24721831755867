/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import graphql from 'services/querys';
import {
  NewBuyerSellerType,
  NewExchange1031Type,
  NewOrderPartyType,
  NewPOAType,
  signatureBlockType,
  UpdateOrderSpecific,
} from 'types/order';
import useAlert from 'utils/alert';
import { INFORMATION, REAL_STATE_AGENT, TRANSACTION_COORDINATOR } from 'utils/constants';

import PartiesContext from '../../services/context';

const useBuyerHooks = (partyId:string, roleParty:'Buyer' | 'Seller') => {
  const context = useContext(PartiesContext);
  const { unfilteredParties, parties } = context || {};
  const [currentTab, setcurrentTab] = useState(INFORMATION);
  const [loaderActive, setLoaderActive] = useState(false);
  const { id: orderId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const methods = useForm<UpdateOrderSpecific>({ mode: 'onChange' });
  const showAlert = useAlert();
  const [udpdateBuyerData, updatBuyerResponse] = useMutation(
    graphql.udpdateOrderSpecific
  );

  useEffect(() => {
    const extraInfo = parties
      ?.find((b) => b.kind === roleParty)
      ?.parties.find((b) => b._id === partyId);
    const { setValue } = methods;
    const signature_default_values: signatureBlockType[] = [
      {
        kind: 'text',
        text: '',
        tabs: 0,
      },
      {
        kind: 'line',
        text: '',
        tabs: 0,
      },
    ];

    if (extraInfo) {
      const {
        vesting_block,
        acknowledgement,
        jurat,
        include_marital,
        include_vesting,
        vesting,
        vesting_type,
        exception_1099,
        ownership,
        declines_ssn_ein,
        is_foreign,
        listed_on_title,
      } = extraInfo.order_data?.find((databuyer) => databuyer.kind === roleParty) as NewBuyerSellerType || {};
      const prefix = roleParty === 'Buyer' ? 'data_buyer' : 'data_seller';
      setValue(
        `${prefix}.vesting_block`,
        vesting_block || signature_default_values
      );
      setValue(`${prefix}.include_marital`, include_marital);
      setValue(`${prefix}.include_vesting`, include_vesting);
      setValue(`${prefix}.vesting`, vesting);
      setValue(`${prefix}.vesting_type`, vesting_type);
      setValue(`${prefix}.acknowledgement`, acknowledgement);
      setValue(`${prefix}.jurat`, jurat);
      setValue(`${prefix}.exception_1099`, exception_1099);
      setValue(`${prefix}.ownership`, ownership);
      setValue(`${prefix}.declines_ssn_ein`, declines_ssn_ein);
      setValue(`${prefix}.is_foreign`, is_foreign);
      setValue(`${prefix}.listed_on_title`, listed_on_title);
    }
  }, [parties, methods, partyId]);

  useEffect(() => {
    const { setValue } = methods;
    if (unfilteredParties) {
      const attorneysLocal: NewOrderPartyType[] = [];
      const agentsLocal: NewOrderPartyType[] = [];
      let partyPOA: NewOrderPartyType | undefined;
      let partyExchange: NewOrderPartyType | undefined;
      let partySpouse: NewOrderPartyType | undefined;
      const realStateAgentList: NewOrderPartyType[] = [];
      const transactionCoordinatorList: NewOrderPartyType[] = [];

      unfilteredParties.forEach((party) => {
        if (party.associations) {
          party.associations.forEach((association) => {
            if (
              association.party_id === partyId
              && association.party_kind === roleParty
            ) {
              if (association.linked_as.includes('Attorney')) {
                attorneysLocal.push(party);
              }
              if (association.linked_as.includes('Agent')) {
                agentsLocal.push(party);
              }
              if (association.linked_as.includes('Power of Attorney')) {
                const { end_date, start_date } = party.order_data?.find(
                  (dataPoa) => dataPoa.kind === 'Power of Attorney'
                ) as NewPOAType;
                setValue('data_power_of_attorney.end_date', end_date);
                setValue('data_power_of_attorney.start_date', start_date);
              }
              if (association.linked_as.includes('Exchange 1031')) {
                const { exchange_1031 } = party.order_data?.find(
                  (dataExchange) => dataExchange.kind === 'Exchange 1031'
                ) as NewExchange1031Type;
                setValue('data_exchange_1031.exchange_1031', exchange_1031);
              }
              if (association.linked_as.includes('Spouse')) {
                partySpouse = party;
              }
              if (association.linked_as.includes(REAL_STATE_AGENT)) {
                realStateAgentList.push(party);
              }
              if (association.linked_as.includes(TRANSACTION_COORDINATOR)) {
                transactionCoordinatorList.push(party);
              }
            }
          });
        }
      });
    }
  }, [unfilteredParties, partyId]);

  useEffect(() => {
    if (updatBuyerResponse.isSuccess) {
      showAlert(`${roleParty} Updated`, 'success');
      updatBuyerResponse.reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatBuyerResponse]);

  return {
    currentTab,
    setcurrentTab,
    t,
    methods,
    udpdateBuyerData,
    orderId,
    loaderActive,
    setLoaderActive,
  };
};

export default useBuyerHooks;
