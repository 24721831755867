import accountingTranslations from './accounting';
import authTranslations from './auth';
import cashToCloseTranslations from './cash-to-close';
import closingDisclosure from './cd-preview';
import chargesTranslations from './charges';
import closing from './closing';
import commonTranslations from './common';
import contactsTranslations from './contact';
import datesAndConfigurationTranslations from './dates-and-config';
import dialogsTranslations from './dialogs';
import disclosureTranslations from './disclosure';
import documentsTranslations from './documents';
import earnestAndCommissionsTranslations from './earnest-and-commissions';
import lenderTranslations from './lender';
import loanTranslations from './loan';
import loanDisclosuresTranslations from './loan-disclosures';
import menuTranslations from './menu';
import notes from './notes';
import notifications from './notifications';
import onboardingTranslations from './onboarding';
import ordersTranslations from './orders';
import ordersPropertiesTranslations from './orders-properties';
import partiesTranslations from './parties';
import paymentsTranslations from './payments';
import payoffsTranslations from './payoffs';
import policyInfoAndRatesTranslations from './policy-info-and-rates';
import projectedPaymentsTranslations from './projected-payments';
import properties from './properties';
import questionsAndAnswers from './questions-and-answers';
import ronTranslations from './ron';
import seller from './seller';
import taxesAndFeesTranslations from './taxes-and-fees';
import taxesAndProrationsTranslations from './taxes-and-prorations';
import teamAndReporting from './team-and-reporting';
import usersTranslations from './users';
import validationsTranslations from './validations';
import disbursmentsTranslations from './disbursments';

export default {
  en: {
    auth: authTranslations.en,
    cashToClose: cashToCloseTranslations.en,
    closingDisclosure: closingDisclosure.en,
    charges: chargesTranslations.en,
    common: commonTranslations.en,
    dialogs: dialogsTranslations.en,
    documents: documentsTranslations.en,
    earnestAndCommissions: earnestAndCommissionsTranslations.en,
    lender: lenderTranslations.en,
    loan: loanTranslations.en,
    loanDisclosures: loanDisclosuresTranslations.en,
    onboarding: onboardingTranslations.en,
    orders: ordersTranslations.en,
    ordersProperties: ordersPropertiesTranslations.en,
    parties: partiesTranslations.en,
    payments: paymentsTranslations.en,
    payoffs: payoffsTranslations.en,
    policyInfoAndRates: policyInfoAndRatesTranslations.en,
    taxesAndProrations: taxesAndProrationsTranslations.en,
    users: usersTranslations.en,
    validations: validationsTranslations.en,
    contact: contactsTranslations.en,
    taxesAndFees: taxesAndFeesTranslations.en,
    disclosure: disclosureTranslations.en,
    projectedPayments: projectedPaymentsTranslations.en,
    datesAndConfig: datesAndConfigurationTranslations.en,
    teamAndReporting: teamAndReporting.en,
    ron: ronTranslations.en,
    closing: closing.en,
    seller: seller.en,
    properties: properties.en,
    accounting: accountingTranslations.en,
    questionsAndAnswers: questionsAndAnswers.en,
    menu: menuTranslations.en,
    notes: notes.en,
    notifications: notifications.en,
    disbursments: disbursmentsTranslations.en,
  },
  es: {
    auth: authTranslations.es,
    cashToClose: cashToCloseTranslations.es,
    closingDisclosure: closingDisclosure.es,
    charges: chargesTranslations.es,
    common: commonTranslations.es,
    dialogs: dialogsTranslations.es,
    documents: documentsTranslations.es,
    earnestAndCommissions: earnestAndCommissionsTranslations.es,
    lender: lenderTranslations.es,
    loan: loanTranslations.es,
    loanDisclosures: loanDisclosuresTranslations.es,
    onboarding: onboardingTranslations.es,
    orders: ordersTranslations.es,
    ordersProperties: ordersPropertiesTranslations.es,
    parties: partiesTranslations.es,
    payments: paymentsTranslations.es,
    payoffs: payoffsTranslations.es,
    policyInfoAndRates: policyInfoAndRatesTranslations.es,
    taxesAndProrations: taxesAndProrationsTranslations.es,
    users: usersTranslations.es,
    validations: validationsTranslations.es,
    contact: contactsTranslations.es,
    taxesAndFees: taxesAndFeesTranslations.es,
    disclosure: disclosureTranslations.es,
    projectedPayments: projectedPaymentsTranslations.es,
    datesAndConfig: datesAndConfigurationTranslations.es,
    teamAndReporting: teamAndReporting.es,
    ron: ronTranslations.es,
    closing: closing.es,
    seller: seller.es,
    properties: properties.es,
    accounting: accountingTranslations.es,
    questionsAndAnswers: questionsAndAnswers.es,
    menu: menuTranslations.es,
    notes: notes.es,
    notifications: notifications.es,
    disbursments: disbursmentsTranslations.es,
  },
};
