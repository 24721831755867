/* eslint-disable import/no-cycle */
import { OrderProperty } from 'types/order';

import { NewAddressType, NewPaymentsType } from './user';

type AccountingInvolvedPartiesMemberOf = {
  _id: string;
  kind: string;
  name: string;
};

export type AccountedBy = {
  amount: number;
  percent: number;
  record: {
    _id: string;
    letter: string;
    number: number;
    description: string;
    code: string;
    amount: number;
    entry_date?: string;
    involved_parties?: {
      _id: string;
      name: string;
      kind: string;
      order_kinds: string[];
    }[];
  };
};

export type AccountingInvolvedParty = {
  _id: string;
  amount: number;
  at_closing_amount: number;
  at_closing_percent: number;
  before_closing_amount: number;
  before_closing_percent: number;
  kind: string;
  member_of: AccountingInvolvedPartiesMemberOf[];
  name: string;
  order_kinds: string[];
  payment_id: string;
  payment_kind: string;
  payment_reference: string;
  percent: number;
  accounted_amount: number;
  accounted_percent: number;
  accounted_by?: AccountedBy[];
};

export type CashToCloseEntry = {
  changed: boolean;
  changed_text: string;
  entry_code: string;
  estimate_amount: number;
};

export type AccountingType = {
  _id: string;
  amount: number;
  amount_calculated: number;
  amount_override: number;
  code: string;
  kind: string;
  letter: string;
  number: number;
  description: string;
  involved_parties: AccountingInvolvedParty[];
  accounted_for?: AccountedBy[];
  entry_date: string;
  months: number | null;
  per_month: number | null;
  months_advanced?: number | null;
  annuality?: number | null;
};

/* eslint-disable no-shadow */
export enum InterestKindEnum {
  adjustable = 'adjustable',
  conversion = 'conversion',
  first = 'first',
  fixed = 'fixed',
  only = 'only',
  subsequent = 'subsequent',
  variable = 'variable',
}

export type Interest =
  | {
      _id: string;
      kind: InterestKindEnum.adjustable;
      index_name: string;
      margin: number;
    }
  | {
      _id: string;
      kind: InterestKindEnum.conversion;
      fee: number;
      period_start: string;
      period_end: string;
    }
  | {
      _id: string;
      kind: InterestKindEnum.first;
      change_date: string;
      change_min: number;
      change_max: number;
    }
  | {
      _id: string;
      kind: InterestKindEnum.fixed | InterestKindEnum.only | InterestKindEnum.variable;
      rate: number;
    }
  | {
      _id: string;
      kind: InterestKindEnum.subsequent;
      change_max: number;
      months: number;
      rate_min: number;
      rate_max: number;
    };

export type AccountingLoanEntry = Omit<AccountingType, 'letter' | 'number' | 'per_month'>;
// eslint-disable-next-line no-shadow
export enum NegativeAmortizationKind {
  feature = 'feature',
  possible = 'possible',
  nonexistent = 'nonexistent',
}

export type Loan = {
  _id: string;
  amount_kind: 'gross' | 'net';
  commitment_date: string;
  construction: boolean;
  construction_initial: number;
  estate: OrderProperty;
  grace_days: number;
  heloc: boolean;
  heloc_draw: string;
  insurance_case: string;
  loan_number: string;
  interests: Interest[];
  mers: boolean;
  mers_min: string;
  payment_first_date: string;
  payment_last_date: string;
  term_months: number;
  term_years: number;
  terms: string;
  purpose: string;
  product: string;
  buyer_issue_date: string;
  seller_issue_date: string;
  late_payment_days: number;
  transfer_assumption: boolean | null;
  demand_feature: boolean | null;
  negative_amortization: NegativeAmortizationKind | null;
  partial_payment_accept: boolean | null;
  partial_payment_denied: boolean | null;
  partial_payment_hold: boolean | null;
  escrow: boolean | null;
  escrow_declined: boolean | null;
  unpaid_liability: boolean | null;
  increase_loan_amount: boolean | null;
  increase_loan_amount_text: string;
  increase_interest_rate: boolean | null;
  increase_interest_rate_text: string;
  increase_monthly_principal: boolean | null;
  increase_monthly_principal_text: string;
  has_prepayment_penalty: boolean | null;
  has_prepayment_penalty_text: string;
  has_balloon_payment: boolean | null;
  has_balloon_payment_text: string;
  penalty_as_percent: boolean;
  accounting: AccountingLoanEntry[];
};

export type AccountingFilter =
  | string
  | 'property'
  | 'premium'
  | 'balance'
  | 'closing'
  | '/^[A-H]$/'
  | 'receipt'
  | 'loan'
  | 'A'
  | 'B'
  | 'C'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'J';
export type DatesAndConfigurationType = {
  acceptance_date: string | null;
  closing_date: string | null;
  contract_date: string | null;
  disbursement_date: string | null;
  funding_date: string | null;
  transaction_type: string;
  type: string;
  workflow: string;
  cash_only: boolean;
  construction_loan: boolean;
  heloc: boolean;
  order_id?: string | null;
  closing_place?: NewAddressType | null;
  additional_fees_letter?: string | null;
};

export type PolicyType = {
  disclosed_payee: string | null;
  disclosure_section: 'B' | 'C' | null;
  endorsements_shown_as: string | null;
  insurance_amount: string | number | null;
  policy_type: string;
  proposed_insured: string;
  rate_type: string;
  remove_section_13: boolean;
  stat_code: string;
};

export type LendersPolicyType = PolicyType;

export type OwnersPolicyType = PolicyType;

export type NewOrderPartyType = {
  _id: string;
  kinds: string[];
  name: string;
  associations: NewAssociationType[] | null;
  member_of: NewMemberOfType[];
  order_data: (NewBuyerSellerType | NewExchange1031Type | NewPOAType)[];
  accounts?: NewPaymentsType[];
  ssn?: string;
};

export type NewAssociationType = {
  linked_as: string;
  party_id: string;
  party_kind: string;
};

export type NewMemberOfType = {
  _id: string;
  kind: string;
  name: string;
};

export type SignatureBlockType = {
  kind: 'line' | 'text';
  text: string;
  tabs: number;
};

export type NewBuyerSellerType = {
  acknowledgement: string;
  declines_ssn_ein: boolean;
  exception_1099: boolean;
  gross_proceeds: number;
  include_marital: boolean;
  include_vesting: boolean;
  is_foreign: boolean;
  jurat: string;
  kind: string;
  kind_owner: boolean;
  listed_on_title: boolean;
  name_control: string;
  ownership: number;
  part_of_tax: number;
  vesting: string;
  vesting_block: SignatureBlockType[];
  vesting_type: string;
};

export type NewPOAType = {
  end_date: string | Date | null;
  kind: string;
  start_date: string | Date | null;
};

export type NewExchange1031Type = {
  kind: string;
  exchange_1031: string;
};

export type UpdateOrderSpecific = {
  _id: string;
  partyId: string;
  data_buyer?: NewBuyerSellerType;
  data_seller?: NewBuyerSellerType;
  data_power_of_attorney?: NewPOAType;
  data_exchange_1031?: NewExchange1031Type;
};

export type NewOrderPartyParams = {
  id: string;
  partyId: string;
  kind: string;
  linkedToId: string;
  linkedToKind: string;
};

export type MilestonesStatus = {
  charges: boolean;
  closing_disclosure: boolean;
  disclosures: boolean;
  general_info: boolean;
  payments: boolean;
  ready_to_close: boolean;
  title: boolean;
};

type PartyInvolvedDocuments = {
  _id: string;
  kind: string;
  address: null | string;
  kinds: string[] | null;
  name: string | null;
};

type ArchiveFile = {
  _id: string;
  data: PartyInvolvedDocuments[] | null;
  download: string;
  filename: string;
  mtime: string;
  size: number;
  source: string;
};

type Archive = {
  _id: string;
  files: ArchiveFile[];
  kinds: string[] | null;
  name: string;
};

type Part = {
  description: string;
  filename: string;
  mtime: string;
  size: number;
  source: string;
};

export type DocumentsType = {
  _id: string;
  archives: Archive[];
  data: PartyInvolvedDocuments[];
  description: string;
  kind: string;
  template: null | string;
  per: string[] | null;
  parts: Part[] | null;
};

export type DocumentPartyRequest = {
  file_id: string;
  party_id: string;
};

// eslint-disable-next-line no-shadow
export enum OrderLedgerKinds {
  DISBURSEMENT = 'disbursement',
  MANUAL = 'manual',
  RECEIPT = 'receipt',
}

// eslint-disable-next-line no-shadow
export enum ScheduleProrationEnum {
  monthly = 'monthly',
  custom_2 = 'custom_2',
  quarterly = 'quarterly',
  custom_4 = 'custom_4',
  custom_5 = 'custom_5',
  semi_annual = 'semi_annual',
  custom_7 = 'custom_7',
  custom_8 = 'custom_8',
  custom_9 = 'custom_9',
  custom_10 = 'custom_10',
  custom_11 = 'custom_11',
  annual = 'annual',
}

export type Proration = {
  _id: string;
  accounting: {
    _id: string;
    amount: number;
    code: string;
    description: string;
  }[];
  days: number;
  days_year: number;
  debited_party: 'buyer_owes_seller' | 'seller_owes_buyer';
  decimals: number;
  end_date: string;
  description: string;
  start_date: string;
  type: 'basic' | 'tax';
  schedule: ScheduleProrationEnum;
  proration_date: string;
  paid_thru: string;
  next_due: string;
  kind: 'installment' | 'per_diem';
  include_1099: boolean;
  impound_disclosure: boolean;
  estate: {
    _id: string;
    address: {
      address: string;
    };
  };
};
