import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import useAlert from "utils/alert";
import graphql from 'services/querys';
import { NewExchange1031Type, NewOrderPartyType, UpdateOrderSpecific } from "types/order";
import { Controller, UseFormReturn } from "react-hook-form";
import { Grid, TextField } from "@material-ui/core";
import { BaseButton } from "components/BaseButton";

type ExchangeFormProps = {
  methods: UseFormReturn<UpdateOrderSpecific, any, undefined>,
  exchangeId: string,
  filterParties: ((parties: NewOrderPartyType[]) => void) | undefined,
}

const ExchangeForm: FC<ExchangeFormProps> = ({ exchangeId, methods, filterParties }) => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();

  const [updateOrderSpecifics, updateOrderSpecificsResponse] = useMutation(graphql.udpdateOrderSpecific, {
    onSuccess: (data) => {
      showAlert('Buyer Updated', 'success');
      if (data?.UpdateOrderSpecifics?.parties && filterParties) {
        filterParties(data?.UpdateOrderSpecifics.parties!);
      }
      updateOrderSpecificsResponse.reset();
    },
    onError: () => {
      updateOrderSpecificsResponse.reset();
      showAlert(t('parties:mutation-error'), 'error');
    },
  });

  const handleSaveOrderSpecifics = ({ dataExchange }: { dataExchange: NewExchange1031Type }) => {
    if (orderId) {
      updateOrderSpecifics({
        id: orderId,
        partyId: exchangeId,
        dataExchange,
      });
    }
  };
  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={9}>
          <Controller
            name="data_exchange_1031.exchange_1031"
            control={methods.control}
            render={({ field: { ref, value, ...field } }) => (
              <TextField
                {...field}
                fullWidth
                inputRef={ref}
                value={value || ''}
                label={t('parties:exchange_1031')}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <BaseButton
            text="Save Information"
            isLoading={updateOrderSpecificsResponse.isLoading}
            disabled={updateOrderSpecificsResponse.isLoading}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const values = methods.getValues();
              handleSaveOrderSpecifics({ dataExchange: values.data_exchange_1031! });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExchangeForm;