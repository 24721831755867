import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import useDisbursments from './hooks/disbursments';
import DisbursmentRow from './components/disbursmentRow';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import { AccountingType } from 'v2-types/order';
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { getHideFields, validationSchemaAdd, validationSchemaEdit } from './services';
import { AddAndUpdatePartyLedgerEntry } from 'components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import ModalLink from './components/modalLink';
import SkeletonDisbursments from './components/skeletonDisbursment';
import { useTranslation } from 'react-i18next';
import { DeleteConfirmationModal } from 'components/delete-confirmation';

const useStyles = makeStyles((theme: any) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: { padding: theme.spacing(3) },
  headerContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.primary.darker,
    fontSize: 24,
    fontWeight: 500,
  },
}));

const Disbursments = () => {
  const classes = useStyles();

  const {
    accounting,
    disbursments,
    isLoading,
    modals,
    handleModals,
    handleDeleteEntry,
    handleDeleteAssociation,
    handledeleteLink,
    handleCreationLink,
    updateDisbursments,
    isLoadingMutations,
    confirmDelate,
    handleConfirmDelete,
  } = useDisbursments();

  const { modal, entry } = modals;
  const { openConfirm, callbackSuccess, title, confirmationMessage } = confirmDelate;
  const resolverAdd = useYupValidationResolver(validationSchemaAdd);
  const resolverEdit = useYupValidationResolver(validationSchemaEdit);
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Paper>
        {isLoading ? (
          <SkeletonDisbursments />
        ) : (
          <>
            <Box className={classes.headerContainer}>
              <Typography className={classes.title} variant="body1">
                {t('disbursments:disbursements')}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleModals('add', null)}
              >
                {t('disbursments:add-entry')}
              </Button>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('disbursments:description')}</TableCell>
                    <TableCell align="center">{t('disbursments:parties')}</TableCell>
                    <TableCell align="center">{t('disbursments:amount')}</TableCell>
                    <TableCell align="center">{t('disbursments:method')}</TableCell>
                    <TableCell align="center">{t('disbursments:date')}</TableCell>
                    <TableCell align="center">{t('disbursments:accounted-for')}</TableCell>
                    <TableCell align="center">{t('disbursments:observations')}</TableCell>
                    <TableCell align="center">{t('disbursments:options')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disbursments.map((item) => (
                    <DisbursmentRow
                      disbursmentItem={item}
                      key={item._id}
                      handleDeleteEntry={handleDeleteEntry}
                      handleModals={handleModals}
                      handleDeleteAssociation={handleDeleteAssociation}
                      handledeleteLink={handledeleteLink}
                      handleConfirmDelete={handleConfirmDelete}
                      t={t}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {modal && (
              <>
                <AddLedgerEntry
                  open={modal === 'add' || modal === 'edit'}
                  handleClose={() => {
                    handleModals(null, null);
                  }}
                  onSuccesAddEntry={updateDisbursments}
                  prevData={modal === 'edit' ? entry : ({ kind: 'manual' } as AccountingType)}
                  kindAllowedEntry={['manual']}
                  codeAllowedEntry={['cash', 'wire', 'check']}
                  resolver={modal === 'edit' ? resolverEdit : resolverAdd}
                  hideFields={getHideFields[modal || 'other']}
                  disableFields={{}}
                  isEditingEntry={modal === 'edit'}
                />

                {entry && modal === 'create' && (
                  <AddAndUpdatePartyLedgerEntry
                    kindAllowedAssociationList={['payee', 'payer']}
                    entryLedger={entry}
                    handleClose={() => {
                      handleModals(null, null);
                    }}
                    handleSuccess={updateDisbursments}
                    hideFields={{
                      amount: true,
                      full_amount: true,
                      percent: true,
                      at_closing_amount: true,
                      at_closing_percent: true,
                      before_closing_amount: true,
                      before_closing_percent: true,
                    }}
                    percent={100}
                  />
                )}

                {entry && modal === 'link' && (
                  <ModalLink
                    handleCreationLink={handleCreationLink}
                    accounting={accounting}
                    handleClose={() => {
                      handleModals(null, null);
                    }}
                    entry={entry}
                  />
                )}
              </>
            )}
          </>
        )}
      </Paper>
      <Backdrop className={classes.backdrop} open={isLoadingMutations}>
        <CircularProgress color="secondary" />
      </Backdrop>

      {callbackSuccess && (
        <DeleteConfirmationModal
          title={title}
          confirmationMessage={confirmationMessage}
          isOpen={openConfirm}
          onConfirm={callbackSuccess}
          onClose={() => handleConfirmDelete(false, null, '', '')}
          isRemoveLoading={false}
        />
      )}
    </Box>
  );
};

export default Disbursments;
