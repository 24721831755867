import { createContext } from 'react';
import { AccountingType } from 'v2-types/order';

import { ListingsType } from './types';

export type updateType = { time: string, type:'error' | 'success' | 'info' | 'warning' | undefined, message:string}

type Context = {
  options: ListingsType;
  accounting: AccountingType[],
  setAccounting: (accountingData:AccountingType[]) => void,
  loading: boolean,
  latestUpdate: updateType | null,
  setLatestUpdate: ({ time, type, message }: updateType)=>void,
  deletedEndorsements: AccountingType[],
  setDeletedEndorsements: React.Dispatch<React.SetStateAction<AccountingType[]>>,
  temporalCreatedEndorsements: AccountingType[],
  setTemporalCreatedEndorsements: React.Dispatch<React.SetStateAction<AccountingType[]>>
};

const PolicyContext = createContext<Context | null>(null);

export default PolicyContext;
