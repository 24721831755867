import useAlert from 'utils/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { getOrder } from '../services/querys';
import { useState } from 'react';
import { AccountingType } from 'v2-types/order';
import useDeleteEntry from 'utils/ledger/use-delete-ledger-entry';
import useDeleteLedgerAssociation from 'utils/ledger/use-delete-ledger-association';
import { useDeleteOrderLedgerLink } from 'hooks/useDeleteOrderLedgerLink';
import { createLedgerLink } from '../services/mutations';
import { filterEntriesToDisbursment } from '../services';

type ModalsType = {
  modal: string | null;
  entry: AccountingType | null;
};

type ConfirmDelateType = {
  openConfirm: boolean;
  callbackSuccess: (() => void) | null;
  title: string;
  confirmationMessage: string;
};

const useDisbursments = () => {
  const showAlert = useAlert();
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const [disbursments, setDisbursments] = useState<AccountingType[]>([]);
  const [modals, setModals] = useState<ModalsType>({
    modal: null,
    entry: null,
  });
  const [confirmDelate, setConfirmDelate] = useState<ConfirmDelateType>({
    openConfirm: false,
    callbackSuccess: null,
    title: '',
    confirmationMessage: '',
  });

  const updateDisbursments = (entries: AccountingType[]) => {
    const { filteredDisbursments, accountingFiltered } = filterEntriesToDisbursment(entries);
    setAccounting(accountingFiltered);
    setDisbursments(filteredDisbursments);
  };

  const { isLoading } = useQuery(
    ['accounting-order-disbursments', orderId],
    () => getOrder(orderId),
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          updateDisbursments(data.getOrder.accounting);
        }
      },
    }
  );

  const handleModals = (modal: string | null, entryInfo: AccountingType | null) => {
    setModals({ modal, entry: entryInfo });
  };

  const { deleteEntry, deleteEntryResponse } = useDeleteEntry(
    (data) => {
      if (data) {
        updateDisbursments(data.deleteOrderLedgerEntry.accounting ?? []);
        showAlert(t('dialogs:order-updated'), 'success');
        deleteEntryResponse.reset();
      }
    },
    () => {
      showAlert(t('dialogs:there-is-an'), 'error');
    }
  );

  const handleDeleteEntry = (accountingId: string) => {
    deleteEntry({
      id: orderId,
      accountingId,
    });
  };

  const { deleteAssociation, deleteAssociationResponse } = useDeleteLedgerAssociation(
    (data) => {
      if (data) {
        updateDisbursments(data.deleteOrderLedgerAssociation.accounting ?? []);
        showAlert(t('dialogs:order-updated'), 'success');
        deleteAssociationResponse.reset();
      }
    },
    () => {
      showAlert(t('dialogs:there-is-an'), 'error');
    }
  );

  const handleDeleteAssociation = (accountingId: string, partyId: string, kind: string) => {
    deleteAssociation({
      id: orderId,
      accountingId,
      kind,
      partyId,
    });
  };

  const [deleteLink, deleteLinkResponse] = useDeleteOrderLedgerLink({
    queryConfig: {
      onSuccess: (data) => {
        if (data) {
          updateDisbursments(data.deleteOrderLedgerLink.accounting ?? []);
          showAlert(t('dialogs:order-updated'), 'success');
          deleteLinkResponse.reset();
        }
      },
      onError: () => {
        showAlert(t('dialogs:there-is-an'), 'error');
      },
    },
  });

  const handledeleteLink = (
    paymentRecordId: string,
    associationKind: string,
    associationId: string,
    associationLedgerRow: string
  ) => {
    deleteLink({
      orderId,
      targetEntryId: associationLedgerRow,
      accountingId: paymentRecordId,
      targetAssociationId: associationId,
      targetAssociationKind: associationKind,
    });
  };

  const [createLedgerLinkMutation, createLedgerLinkResponse] = useMutation(createLedgerLink, {
    onSuccess: (data) => {
      if (data) {
        updateDisbursments(data.createOrderLedgerLink.accounting ?? []);
        showAlert(t('dialogs:order-updated'), 'success');
        createLedgerLinkResponse.reset();
      }
    },
    onError: () => {
      showAlert(t('dialogs:there-is-an'), 'error');
    },
  });

  const handleCreationLink = ({
    targetEntryId,
    targetAssociationId,
    targetAssociationKind,
    amount,
  }) => {
    createLedgerLinkMutation({
      id: orderId,
      accountingId: modals.entry?._id || '',
      targetEntryId,
      targetAssociationId,
      targetAssociationKind,
      amount: parseFloat(amount as string) || 0,
    });
  };

  const handleConfirmDelete = (
    openConfirm: boolean,
    callback: (() => void) | null,
    title: string,
    confirmationMessage: string
  ) => {
    setConfirmDelate({
      openConfirm,
      title,
      confirmationMessage,
      callbackSuccess: () => {
        if (callback) {
          callback();
          handleConfirmDelete(false, null, '', '');
        }
      },
    });
  };

  return {
    disbursments,
    isLoading,
    handleModals,
    modals,
    handleDeleteEntry,
    handleDeleteAssociation,
    handledeleteLink,
    accounting,
    handleCreationLink,
    updateDisbursments,
    isLoadingMutations:
      createLedgerLinkResponse.isLoading ||
      deleteLinkResponse.isLoading ||
      deleteEntryResponse.isLoading ||
      deleteAssociationResponse.isLoading,
    confirmDelate,
    handleConfirmDelete,
  };
};

export default useDisbursments;
